import { getProviders, signIn } from 'next-auth/react'
import Image, { StaticImageData } from 'next/image'
import SNSIcon from '~/components/icons/SNSIcon'
import BlankLayout from '~/components/layouts/blank'
import { Button } from '~/components/shadcn/button'

import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import { getServerSession } from 'next-auth'
import { getToken } from 'next-auth/jwt'
import { type ReactElement, useEffect } from 'react'
import { authConfig } from '~/auth'
import { useRandomProductImageQuery } from '~/hooks'

import Head from 'next/head'
import placeholder from 'public/images/airJordan1.jpg'

export default function Login({ providers }: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { data, isLoading } = useRandomProductImageQuery()
  let imageUrl = ''

  if (!isLoading) {
    if (data?.imageUrl && data.imageUrl !== '/images/rafflePlaceholder.png') {
      imageUrl = data.imageUrl
    } else {
      imageUrl = placeholder.src
    }
  }

  return (
    <div className='flex w-full h-screen'>
      <Head>
        <title>Login</title>
      </Head>

      <div className='w-[480px] h-screen'>
        {Object.values(providers).map((provider) => (
          <div key={provider.name} className='h-screen flex justify-center items-center '>
            <div style={{ marginBottom: 200 }}>
              <SNSIcon width={'200'} height={'120'} />
              <Button variant='outline' className='w-full' onClick={() => void signIn(provider.id)}>
                Login
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div
        className='flex bg-[#f6f6f6] text-white h-screen grow bg-contain bg-center bg-no-repeat'
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        &nbsp;
      </div>
    </div>
  )
}

Login.getLayout = function getLayout(page: ReactElement) {
  return <BlankLayout>{page}</BlankLayout>
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authConfig)

  // If the user is already logged in, redirect.
  // Note: Make sure not to redirect to the same page to avoid an infinite loop!
  if (session) {
    console.log('Redirecting to frontpage because you have a session')
    return { redirect: { destination: '/' } }
  }

  const providers = await getProviders()

  return {
    props: { providers: providers ?? [] },
  }
}
